<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("agencyProfile.province") }}</label>
                <a-select
                    v-model="model.province_code"
                    :options="provinceList"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    @change="onProvinceChange"
                    class="tw-w-full"
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("agencyProfile.district") }}</label>
                <a-select
                    v-model="model.district_code"
                    :options="districtList"
                    @change="onDistrictChange"
                    class="tw-w-full"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("agencyProfile.commune") }}</label>
                <a-select
                    v-model="model.commune_code"
                    :options="communeList"
                    @change="onCommuneChange"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    class="tw-w-full"
                ></a-select>
            </div>
        </div>
        <div class="row">
            <div class="co-md-12 tw-space-y-1">
                <label>{{ $t("agencyProfile.village") }}</label>
                <a-select
                    v-model="model.village_code"
                    :options="villageList"
                    showSearch
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    class="tw-w-full"
                ></a-select>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
export default {
    name: "addressAgency",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            addresses: {
                provinces: [],
                districts: [],
                communes: [],
                villages: []
            },
            model: {}
        };
    },
    created() {
        this.model = this.value;
        if (isEmpty(this.edit_data)) {
            this.fetchFormView();
        }
    },
    computed: {
        ...mapState("agency/agencyProfile", ["edit_data"]),
        provinceList() {
            if (!this.addresses.provinces) return [];

            return this.addresses.provinces.map(el => ({
                value: el.province_code,
                label: el.name_en
            }));
        },
        districtList() {
            if (!this.addresses.districts) return [];

            return this.addresses.districts.map(el => ({
                value: el.district_code,
                label: el.name_en
            }));
        },
        communeList() {
            if (!this.addresses.communes) return [];

            return this.addresses.communes.map(el => ({
                value: el.commune_code,
                label: el.name_en
            }));
        },
        villageList() {
            if (!this.addresses.villages) return [];

            return this.addresses.villages.map(el => ({
                value: el.village_code,
                label: el.name_en
            }));
        }
    },
    methods: {
        ...mapActions("agency/agencyProfile", ["getFormViewData"]),
        fetchFormView(control, params) {
            this.$emit("loading", true);
            this.getFormViewData(params)
                .then(result => {
                    if (!control) {
                        this.addresses = result.data;
                    }
                    this.addresses[control] = result.data[control];
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.$emit("loading", false);
                });
        },
        onProvinceChange(value) {
            this.model.district_code = "";
            this.model.commune_code = "";
            this.model.village_code = "";
            this.fetchFormView("districts", {
                params: {
                    provinceCode: value,
                    districtCode: "",
                    communeCode: ""
                }
            });
        },
        onDistrictChange(value) {
            this.model.commune_code = "";
            this.model.village_code = "";
            this.fetchFormView("communes", {
                params: {
                    provinceCode: "",
                    districtCode: value,
                    communeCode: ""
                }
            });
        },
        onCommuneChange(value) {
            this.model.village_code = "";
            this.fetchFormView("villages", {
                params: {
                    provinceCode: "",
                    districtCode: "",
                    communeCode: value
                }
            });
        },
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
