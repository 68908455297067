<template>
    <div>
        <div>
            <label>{{ $t("agencyProfile.typeYourLocation") }}</label>
            <TsMapAutocomplete @setLocation="setPlaceOrLocation" />
        </div>
        <GmapMap
            :center="center"
            :zoom="zoom"
            :options="{
                streetViewControl: true
            }"
            map-type-id="roadmap"
            class="tw-w-full tw-h-44 tw-mt-2"
            @click="mapClick"
        >
            <GmapMarker
                :clickable="true"
                :draggable="true"
                :position="marker"
            />
        </GmapMap>
    </div>
</template>

<script>
export default {
    name: "geoLocationAgency",
    props: ["value"],
    data() {
        return {
            model: {},
            zoom: 7,
            center: { lat: 12.565679, lng: 104.990967 },
            marker: { lat: undefined, lng: undefined }
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
        mapClick(value) {
            this.marker.lat = value.latLng.lat();
            this.marker.lng = value.latLng.lng();
            this.model.geo_location.lat = value.latLng.lat();
            this.model.geo_location.lng = value.latLng.lng();
        },
        setPlaceOrLocation(location) {
            this.center = {
                lat: location.lat,
                lng: location.lng
            };
            this.marker = this.center;
            (this.model.geo_location.lat = location.lat),
                (this.model.geo_location.lng = location.lng);
            this.zoom = 18;
        }
    }
};
</script>

<style lang="css">
.pac-icon {
    display: none !important;
}
</style>
