<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.agencyName")
                    }}</label>
                    <input
                        v-model.trim="model.agency_name"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('agency_name') }"
                        :placeholder="$t('agencyProfile.agencyName')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('agency_name')"
                    >
                        {{ errors.first("agency_name") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.contactPerson")
                    }}</label>
                    <input
                        v-model.trim="model.contact_person"
                        type="text"
                        class="form-control"
                        :placeholder="$t('agencyProfile.contactPerson')"
                        :class="{ 'is-invalid': errors.has('contact_person') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('contact_person')"
                    >
                        {{ errors.first("contact_person") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.phoneNumber")
                    }}</label>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                        <div>
                            <input
                                v-model.trim="model.phone1"
                                type="text"
                                class="form-control"
                                :placeholder="$t('agencyProfile.line1')"
                                :class="{ 'is-invalid': errors.has('phone1') }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('phone1')"
                            >
                                {{ errors.first("phone1") }}
                            </div>
                        </div>
                        <div>
													<input
                            v-model.trim="model.phone2"
                            type="text"
                            class="form-control"
                            :placeholder="$t('agencyProfile.line2')"
                        />
												</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t("agencyProfile.email") }}</label>
                    <input
                        v-model.trim="model.email"
                        type="text"
                        class="form-control"
                        :placeholder="$t('agencyProfile.email')"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.contractDate")
                    }}</label>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                        <div>
                            <a-date-picker
                                v-model="model.contract_start_date"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                placeholder="Start Date"
                                class="tw-w-full"
                            />
														<div
                                class="tw-text-red-600"
                                v-if="errors.has('contract_start_date')"
                            >
                                {{ errors.first("contract_start_date") }}
                            </div>
                        </div>
                        <div>
                            <a-date-picker
                                v-model="model.contract_end_date"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                placeholder="End Date"
                                class="tw-w-full"
                            />
														<div
                                class="tw-text-red-600"
                                v-if="errors.has('contract_end_date')"
                            >
                                {{ errors.first("contract_end_date") }}
                            </div>														
                        </div>
                    </div>
                </div>
            </div>
						<div class="row">
							<div class="col-12 tw-flex tw-space-x-3">
								<ts-checkbox
									v-model="model.is_company_agency"
								>{{ $t('agencyProfile.companyAgency') }}</ts-checkbox>
								<ts-checkbox
									v-model="model.is_active"
								>{{ $t('agencyProfile.active') }}</ts-checkbox>
							</div>
						</div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
export default {
    name: "basicInformationAgency",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
