<template>
    <div
        class="tw-border tw-h-36 tw-w-full tw-rounded-lg tw-p-1 tw-bg-white tw-overflow-hidden tw-relative"
        @mouseover="() => (showBrowseFileBotton = true)"
        @mouseleave="() => (showBrowseFileBotton = false)"
    >
        <img
            :src="logo_file || !value ? logo_file_src : value"
            alt=""
            class="tw-object-cover tw-w-full tw-h-full"
        />
        <div
            class="tw-w-full tw-h-full tw-opacity-30 tw-bg-gray-600 tw-absolute tw-top-0 tw-left-0 tw-transform tw-duration-500"
            v-show="showBrowseFileBotton"
        ></div>
        <div
            class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center"
            v-show="showBrowseFileBotton"
        >
            <label
                for="logo-image-front"
                class="tw-text-white tw-p-2 tw-bg-blue-500 tw-opacity-100 tw-cursor-pointer tw-rounded-lg"
                >{{ $t("browseFile") }}</label
            >
            <input
                type="file"
                id="logo-image-front"
                ref="logoimagefront"
                style="display:none"
								@change="onFileChange"
            />
        </div>
    </div>
</template>

<script>
export default {
		props: ['value'],
    data() {
        return {
            showBrowseFileBotton: false,
            logo_file_src: require("@/assets/default-image.jpg"),
						logo_file: ''
        };
    },
    methods: {
        onFileChange() {
            const file = this.$refs.logoimagefront.files[0];
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                function() {
                    this.logo_file_src = reader.result;
                }.bind(this),
                false
            );
            if (file) {
                if (/\.(jpe?g|png)$/i.test(file.name)) {
                    reader.readAsDataURL(file);
                    this.logo_file = file;
										this.$emit('change', file)
                } else {
                    this.logo_file_src = require("@/assets/default-image.jpg");
                    this.$toasted.error("invalid file type!");
                    this.$refs.logo_file.value = "";
										this.$emit('change', '')
                }
            }
        }
    }
};
</script>

<style></style>
