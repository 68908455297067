<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h5>{{ $t("agencyProfile.bankAccount") }}</h5>
            <div style="height:215px" class="tw-overflow-y-scroll">
                <table class="tw-w-full">
                    <thead
                        class="tw-border-b tw-border-gray-500 tw-bg-gray-100"
                    >
                        <tr>
                            <th class="tw-py-2 tw-px-1 tw-w-10 tw-text-center">
                                {{ $t("agencyProfile.no") }}
                            </th>
                            <th class="tw-py-2 tw-px-1">
                                {{ $t("agencyProfile.bankName") }}
                            </th>
                            <th class="tw-py-2 tw-px-1">
                                {{ $t("agencyProfile.accountName") }}
                            </th>
                            <th class="tw-py-2 tw-px-1">
                                {{ $t("agencyProfile.accountNumber") }}
                            </th>
                            <th class="tw-w-8 tw-py-2 tw-px-1">
                                <a
                                    @click.prevent="addBank"
                                    href="#"
                                    class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                                    ><i class="fa fa-plus"></i
                                ></a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(p, index) in model.payment_info"
                            :key="index"
														class="tw-border-b"
                        >
                            <td class="tw-text-center">{{ index + 1 }}</td>
                            <td class="tw-py-1 tw-px-2">
                                <input
                                    class="tw-outline-none tw-rounded tw-border-l tw-border-r tw-py-1 tw-px-2 tw-w-full"
                                    type="text"
                                    v-model.trim="p.bank_name"
																		:placeholder="errors.first('payment_info.'+index+ '.bank_name')"
                                />
                            </td>
                            <td class="tw-py-1 tw-px-2">
                                <input
                                    class="tw-outline-none tw-rounded tw-border-l tw-border-r tw-py-1 tw-px-2 tw-w-full"
                                    type="text"
                                    v-model.trim="p.account_name"
																		:placeholder="errors.first('payment_info.'+index+ '.account_name')"
                                />
                            </td>
                            <td class="tw-py-1 tw-px-2">
                                <input
                                    class="tw-outline-none tw-rounded tw-border-l tw-border-r tw-py-1 tw-px-2 tw-w-full"
                                    type="text"
                                    v-model.trim="p.account_number"
																		:placeholder="errors.first('payment_info.'+index+ '.account_number')"
                                />
                            </td>
                            <td class="tw-text-center">
                                <a href="#" class="tw-text-red-600"
																	@click.prevent="removeBank(index)"
																>
																	<i class="fa fa-trash"></i>
																</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
export default {
    name: "bankAccountAgency",
    props: ["value", "validate"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
        addBank() {
            this.model.payment_info.push({
                bank_name: "",
                account_name: "",
                account_number: ""
            });
        },
				removeBank(index){
					this.model.payment_info.splice(index, 1)
				}
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
